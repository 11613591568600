<template>
  <div>
    <v-dialog v-model="dialog" max-width="450px" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
          <v-icon dark>
            mdi-plus
          </v-icon>
          Create
        </v-btn>
      </template>
      <v-card tile>
        <v-card-title class="primary white--text">
          <span class="text-h5"><v-icon large color="white" class="mr-2">{{ formIcon }}</v-icon>{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field outlined dense v-model="editedItem.name" :error-messages="nameErrors" required
                  @input="editedIndex == -1 ? $v.editedItem.name.$touch() : null"
                  @blur="editedIndex == -1 ? $v.editedItem.name.$touch() : null" label="Title"
                  append-icon="mdi-rename-box"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense v-model="editedItem.code" :error-messages="codeErrors" required
                  @input="editedIndex == -1 ? $v.editedItem.code.$touch() : null"
                  @blur="editedIndex == -1 ? $v.editedItem.code.$touch() : null" label="Code"
                  append-icon="mdi-rename-box"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense v-model="editedItem.discount" :error-messages="discountErrors" required
                  @input="editedIndex == -1 ? $v.editedItem.discount.$touch() : null"
                  @blur="editedIndex == -1 ? $v.editedItem.discount.$touch() : null" label="Value"
                  append-icon="mdi-cash"></v-text-field>
              </v-col>
              <v-col cols="12">
                                  <label>Is Visible in app?</label>
                                  <v-radio-group required v-model="editedItem.visible" row>
                                      <v-radio label="Yes" :value="true"></v-radio>
                                      <v-radio label="No" :value="false"></v-radio>
                                  </v-radio-group>
                              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense v-model="editedItem.minimum_price" :error-messages="minimum_priceErrors"
                  required @input="editedIndex == -1 ? $v.editedItem.minimum_price.$touch() : null"
                  @blur="editedIndex == -1 ? $v.editedItem.minimum_price.$touch() : null" label="Minimum Price"
                  append-icon="mdi-cash"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense v-model="editedItem.maximum_price" :error-messages="maximum_priceErrors"
                  required @input="editedIndex == -1 ? $v.editedItem.maximum_price.$touch() : null"
                  @blur="editedIndex == -1 ? $v.editedItem.maximum_price.$touch() : null" label="Maximum Price"
                  append-icon="mdi-cash"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert v-if="error" dense type="error">
                  {{ error }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider class="primary" />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="close">
            Cancel
          </v-btn>
          <v-btn color="success" @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="error white--text"><v-icon color="white" class="mr-2">mdi-delete</v-icon> You sure you want
          to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDelete">Cancel</v-btn>
          <v-btn color="success" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table :headers="headers" :items="data" sort-by="name" class="elevation-3">
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2 warning--text" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)" class="error--text">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-progress-circular v-if="loading" indeterminate color="secondary"></v-progress-circular>
        <v-icon v-else x-large color="grey lighten-1">
          mdi-tray-remove
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { required, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
export default {
  name: 'Promos',
  mixins: [validationMixin],
  data: () => ({
    headers: [
      {
        text: 'Promo Code Name',
        value: 'name',
      },
      {
        text: 'Promo Code',
        value: 'code',
      },
      { text: 'Value', value: 'discount' },
      { text: 'Visible', value: 'visible' },
      { text: 'Minimum Price', value: 'minimum_price' },
      { text: 'Maximum Price', value: 'maximum_price' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    data: [],
    apiUrl: process.env.VUE_APP_API_URL,
    loading: true,
    dialog: false,
    dialogDelete: false,
    error: '',
    editedIndex: -1,
    editedItem: {
      name: '',
      code: '',
      discount: 0,
      visible: true,
      minimum_price: 0,
      maximum_price: 0,
    },
    defaultItem: {
      name: '',
      code: '',
      discount: 0,
      visible: true,
      minimum_price: 0,
      maximum_price: 0,
    },
  }),
  created() {
    this.getPromos();
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.img_url = null
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deletePromo(this.editedItem.id)
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$v.$reset()
      this.error = ''
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.editedIndex == -1 ? this.addPromo() : this.updatePromo()
    },
    //Promos API Calls
    getPromos() {
      this.loading = true
      this.$Axios
        .get('/api/v1/promocode/')
        .then((res) => {
          this.data = res.data;
          console.log(this.data);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false
        })
    },
    addPromo() {

      this.error = ''
      this.$v.$touch()
      console.log(this.$v)
      if (this.$v.$error) {
        return
      }

      let formData = new FormData();
      formData.append('name', this.editedItem.name);
      formData.append('code', this.editedItem.code);
      formData.append('discount', this.editedItem.discount);
      formData.append('minimum_price', this.editedItem.minimum_price);
      formData.append('maximum_price', this.editedItem.maximum_price);
      formData.append('visible', this.editedItem.visible);
      console.log('adding item : ', formData);
      this.$Axios({
        method: 'post',
        url: '/api/v1/promocode/',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => {
          this.getPromos();
          this.close();
        })
        .catch((e) => {
          console.log(e)
        });
    },
    updatePromo() {
      this.error = ''
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }
      let formData = new FormData();
      if (this.editedItem.name) formData.append('name', this.editedItem.name);
      if (this.editedItem.code) formData.append('code', this.editedItem.code);
      if (this.editedItem.discount) formData.append('discount', this.editedItem.discount);
      if (this.editedItem.minimum_price) formData.append('minimum_price', this.editedItem.minimum_price);
      if (this.editedItem.maximum_price) formData.append('maximum_price', this.editedItem.maximum_price);
      if (this.editedItem.visible) formData.append('visible', this.editedItem.visible);
      console.log('adding item : ', formData);
      this.$Axios({
        method: 'put',
        url: '/api/v1/promocode/' + this.editedItem.id,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(() => {
          this.getPromos();
          this.close();
        })
        .catch((e) => {
          console.log(e)
        });
    },
    deletePromo(id) {
      this.$Axios
        .delete('/api/v1/promocode/' + id)
        .then(() => {
          this.getPromos();
        })
        .catch((e) => {
          console.log(e);
        })
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Promo Code' : 'Edit Promo Code';
    },
    formIcon() {
      return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
    },
    filtredItems() {
      if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter))
      else return this.desserts
    },
    //// Vuelidate Errors
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('name is required !')
      return errors
    },
    codeErrors() {
      const errors = []
      if (!this.$v.editedItem.code.$dirty) return errors
      !this.$v.editedItem.code.required && errors.push('code is required !')
      return errors
    },
    discountErrors() {
      const errors = []
      if (!this.$v.editedItem.discount.$dirty) return errors
      !this.$v.editedItem.discount.required && errors.push('Value is required !')
      !this.$v.editedItem.discount.numeric && errors.push('Value must be a number !')
      return errors
    },
    minimum_priceErrors() {
      const errors = []
      if (!this.$v.editedItem.minimum_price.$dirty) return errors
      !this.$v.editedItem.minimum_price.required && errors.push('Minimum price is required !')
      !this.$v.editedItem.minimum_price.numeric && errors.push('Minimum price be a number !')
      return errors
    },
    maximum_priceErrors() {
      const errors = []
      if (!this.$v.editedItem.maximum_price.$dirty) return errors
      !this.$v.editedItem.maximum_price.required && errors.push('Maximum price is required !')
      !this.$v.editedItem.maximum_price.numeric && errors.push('Maximum price be a number !')
      return errors
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  validations: {
    editedItem: {
      name: {
        required
      },
      code: {
        required
      },
      discount: {
        required,
        numeric
      },
      minimum_price: {
        required,
        numeric
      },
      maximum_price: {
        required,
        numeric
      },
    },
  },
};
</script>
